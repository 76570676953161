<template lang="">
<div>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                คุณต้องการทำแบบประเมินต่อหรือไม่?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="stopTesting">
                    กลับสู่หน้าหลัก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1"  @click="dialog = false,$router.go()">
                    เริ่มทำแบบประเมินอีกครั้ง
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-row     class="d-flex justify-center" v-if="score > 3 && isLoaded" :class="$vuetify.breakpoint.mobile ? 'ct_s_mobile' : 'ct_s_desktop'">
        <v-col cols="12">
            <v-btn icon @click="goProvider">
                <v-img src="@/assets/img/checkmong/Asset 26.png" contain  height="30rem" width="15rem"></v-img>
            </v-btn>
        </v-col>
        <br>
        <br>
        <br>
        
        <v-col cols="12">
            <div class="text-center pt-9 pl-6">
                <v-btn rounded color="#d55196" x-large dark @click="goProvider">
                    ปรึกษาหมอใกล้บ้าน
                </v-btn>
            </div>
        </v-col>
    </v-row>

    <v-row v-if="score <= 3 && isLoaded" :class="$vuetify.breakpoint.mobile ? 'ct_s_mobile' : 'ct_s_desktop'">
        <v-col cols="12">
            <v-btn icon @click="goKnowladge">
                <v-img src="@/assets/img/checkmong/pending_actionsmeds.png" contain aspect-ratio="1.7" width="300"></v-img>
            </v-btn>
        </v-col>
        <br>
        <br>
        <br>
        <v-col cols="12">
            <div class="text-center pt-9 pl-0">
                <v-btn rounded color="#d55196" x-large dark @click="goKnowladge">
                    ติดตามรับข้อมูลเพิ่มเติม<br>
                    เกี่ยวกับภาวะสมองเสื่อม
                </v-btn>
            </div>
        </v-col>
    </v-row>

    <div :style="image" class="imgbox_3">
        <div class="h-screen" v-resize="onResize">
            <v-btn :class="$vuetify.breakpoint.mobile ? 's_mobile' : 's_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" @click="checkStop" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" text absolute icon fab>
                <v-icon x-large color="#A84192">close</v-icon>
            </v-btn>
            <!-- <v-btn :class="$vuetify.breakpoint.mobile ? 's_next_mobile' : 's_next_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" @click="onTouch" v-show="true" text center absolute icon fab>
                <v-icon large color="#A84192">arrow_forward_ios</v-icon>
            </v-btn> -->
            <v-img position="center top" eager :contain="!$vuetify.breakpoint.mobile" height="100vh" src="@/assets/img/checkmong/azq/scene30.gif" @load="onImgLoad">
                <template v-slot:placeholder v-if="!isLoaded">
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
            </v-img>
            <!-- <v-img v-if="$vuetify.breakpoint.mobile" position="center center" height="100vh" eager @load="onImgLoad" src="@/assets/img/checkmong/azq/scene1.gif"></v-img> -->

        </div>
    </div>
</div>
</template>

<script>
import {
    LIFFID
} from '@/constants'
export default {
    props: ['score'],
    data() {
        return {
            isLoaded:false,
            current_scene_w: 0,
            windowSize: {
                x: 0,
                y: 0,
            },
            dialog: false,
            play_icon: 'record_voice_over',
            playing: false,
            hidden: true,
            audio: new Audio(require('@/assets/img/checkmong/azq/audio/voice/21.mp3')),
            yes_png: require("@/assets/img/checkmong/azq/button/Yes.png"),
            no_png: require("@/assets/img/checkmong/azq/button/No.png"),
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_yellow.jpg')})`
            }
        };
    },
    mounted() {
        this.onResize()
    },
    watch: {
        'audio.paused'(val) {
            //console.log(val)
        }
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {//console.log('done window.liff.init')});
    },
    methods: {
        onImgLoad() {
            setTimeout(() => {
                this.isLoaded = true
                //console.log('isLoaded---->', this.isLoaded)
            }, 10);
        },
        checkStop() {
            // 
            clearTimeout(this.time);
            this.dialog = true;
            //console.log('(this.time):', this.time)

        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:', this.windowSize)
            this.current_scene_w = this.windowSize.y * 0.45
            //console.log('current_scene_w:', this.current_scene_w)
        },
        goProvider() {
            
            window.open("https://liff.line.me/1657454775-4m28le5g");
            this.onTouch()
        },
        goKnowladge() {
            window.open("https://liff.line.me/1657454775-evNEMK8g");
            this.onTouch()
        },
        stopTesting() {
                window.liff.closeWindow();
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'stop_testing': '1'
                });
            

        },
        
        togleAudio(audio) {
            this.playing = !this.playing
            if (this.playing) {
                this.play(audio)
                this.audio.volume = 1
                this.play_icon = 'stop'
            } else {
                this.pause(audio)
                this.play_icon = 'record_voice_over'
            }

        },

        play(audio) {
            audio.isPlaying = true;
            audio.play();
        },

        pause(audio) {
            audio.isPlaying = false;
            audio.pause();
            audio.currentTime = 0;
        },
        toggle_yes() {
            this.yes_png = require("@/assets/img/checkmong/azq/button/Yes_active.png")
            this.no_png = ''
            setTimeout(() => {
                this.onTouch(true)
            }, 1000);
        },
        toggle_no() {
            this.no_png = require("@/assets/img/checkmong/azq/button/No_active.png")
            this.yes_png = ''
            setTimeout(() => {
                this.onTouch(false)
            }, 1000);
        },
        onTouch() {

            // const user_type =  this.$store.state.register_form.user_type
            const azq_ans_temp = this.$store.state.azq_ans

            //console.log(this.azq_ans_temp)
            // //console.log(current_ans)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'current_s': 's_30',

            });
        },
    },

};
</script>

<style>
.imgbox_2 {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 850px;
}

.ct_s_mobile {
    position: absolute;
    z-index: 999;
    text-align: center;
    width: 100%;
    top: 15%;
    right:12px;
}

.ct_s_desktop {
    position: absolute;
    z-index: 999;
    top: 0%;
    margin-left: -130px;
    left: 50%;
    top: 20%;
    text-align: center;
}
</style>
