<template lang="">
<div>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                คุณต้องการทำแบบประเมินต่อหรือไม่?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="stopTesting">
                    กลับสู่หน้าหลัก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1"  @click="dialog = false">

                    ทำแบบประเมินต่อ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <div :style="image" class="imgbox_2"></div> -->
    <v-row v-if="isLoaded"  :class="$vuetify.breakpoint.mobile ? 'ans_s_mobile' : 'ans_s_desktop'">
        <v-col cols="6">
            <v-btn icon @click="toggle_yes">
                <v-img :src="yes_png" contain aspect-ratio="1.7" width="90px"></v-img>
            </v-btn>
        </v-col>
        <v-col cols="6">
            <v-btn icon  @click="toggle_no">
                <v-img :src="no_png"  contain aspect-ratio="1.7" width="90px"></v-img>
            </v-btn>
        </v-col>
    </v-row>



    <div :style="image" class="imgbox_3">
        <div class="h-screen" v-resize="onResize">
            <!-- <v-btn @click="togleAudio(audio)" :class="$vuetify.breakpoint.mobile ? 's_voice_mobile' : 's_voice_desktop'"  :style="{ 'margin-left': ((current_scene_w/2)-10)*-1 + 'px' }" v-if="isLoaded" :left="$vuetify.breakpoint.mobile" text absolute icon fab><v-icon large color="#A84192">{{`${play_icon}`}}</v-icon></v-btn> -->
            <v-btn @click="togleAudio(audio)" :class="$vuetify.breakpoint.mobile ? 's_voice_mobile' : 's_voice_desktop'"  :style="{ 'margin-left': ((current_scene_w/2)-10)*-1 + 'px' }" v-if="isLoaded" :left="$vuetify.breakpoint.mobile" text absolute icon fab><v-icon large color="#A84192">{{`${play_icon}`}}</v-icon></v-btn>

            <v-btn :class="$vuetify.breakpoint.mobile ? 's_mobile' : 's_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" @click="checkStop" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" text absolute icon fab>
                <v-icon x-large color="#A84192">close</v-icon>
            </v-btn>
            <!-- <v-btn :class="$vuetify.breakpoint.mobile ? 's_next_mobile' : 's_next_desktop'"  :style="{ 'margin-': ((current_scene_w/2)-56) + 'px' }"   v-if="isLoaded" :right="$vuetify.breakpoint.mobile" @click="onTouch" v-show="true" text center absolute icon fab>
                <v-icon large color="#A84192">arrow_forward_ios</v-icon>
            </v-btn> -->
          <v-img position="center top"  :contain="!$vuetify.breakpoint.mobile" height="100vh" src="@/assets/img/checkmong/azq/scene7.gif" @load="onImgLoad">
            <template v-slot:placeholder v-if="!isLoaded">
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
          </v-img>
          <!-- <v-img v-if="$vuetify.breakpoint.mobile" position="center top" cover height="100vh" eager @load="onImgLoad" src="@/assets/img/checkmong/azq/scene7.gif"></v-img> -->
        </div>
    </div>




</div>
</template>

<script>
    import {
    LIFFID
} from '@/constants'
export default {

    data() {
        return {
            current_scene_w: 0,
            dialog: false,
            windowSize: {
                x: 0,
                y: 0,
            },
            isLoaded: false,
            play_icon: 'record_voice_over',
            playing: true,
            hidden: true,
            audio: new Audio(require('@/assets/img/checkmong/azq/audio/voice/1.mp3')),
            yes_png: require("@/assets/img/checkmong/azq/button/Yes.png"),
            no_png: require("@/assets/img/checkmong/azq/button/No.png"),
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_brown.jpg')})`
            },
        };
    },
    mounted() {
    //    this.audio.autoplay = true;
       this.playing = this.$store.state.azq_ans.voice_on
       this.onResize() 
    },
    watch: {
        'audio.paused'(val) {
            //console.log(val)
        }
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    methods: {
        toggleApi() {
            //console.log('77777toggleApi')
            this.$fullscreen.toggle()
            this.hidden = !this.hidden
        },
        stopTesting() {
                window.liff.closeWindow();
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'stop_testing': '1'
                });
            

        },
        checkStop(){
            this.dialog = true;

        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:',this.windowSize)
            this.current_scene_w = this.windowSize.y*0.45
            //console.log('current_scene_w:',this.current_scene_w)
        },
        onImgLoad(){
            //console.log(this.isLoaded)
            this.togleAudio(this.audio)
            setTimeout(() => {
                this.isLoaded = true
            }, 10);
        },
        togleAudio(audio) {
            this.playing = !this.playing
            if (this.playing) {
                this.play(audio)
                this.audio.volume = 1
                this.play_icon = 'record_voice_over'
            } else {
                this.pause(audio)
                this.play_icon = 'voice_over_off'
            }

        },

        togleAudio_bg_sound() {
            const audio = this.$store.state.azq_ans.bg_sound
            audio.isPlaying = true;
            if (typeof audio.loop == 'boolean') {
                audio.loop = true;
            } else {
                audio.addEventListener('ended', function () {
                    this.currentTime = 0;
                    this.play();
                }, false);
            }
            audio.play();
        },
        pauseBgSound() {
            const audio = this.$store.state.azq_ans.bg_sound
            audio.isPlaying = false;
            audio.pause();
            audio.currentTime = 0;
        },




        play(audio) {
            audio.isPlaying = true;
            audio.muted = false
            audio.autoplay = true;
            audio.play();
        },

        pause(audio) {
            audio.isPlaying = false;
            audio.pause();
            audio.currentTime = 0;
        },
        toggle_yes() {
            this.yes_png = require("@/assets/img/checkmong/azq/button/Yes_active.png")
            this.no_png = ''
            setTimeout(() => {
                this.onTouch(true)
            }, 1000);
        },
        toggle_no() {
            this.no_png = require("@/assets/img/checkmong/azq/button/No_active.png")
            this.yes_png = ''
            setTimeout(() => {
                this.onTouch(false)
            }, 1000);
        },
        onTouch(val) {

            // const user_type =  this.$store.state.register_form.user_type
            // const lineid = this.$store.state.register_form.lineid
            this.pause(this.audio)
            const azq_ans_temp = this.$store.state.azq_ans

            //console.log(this.azq_ans_temp)
            // //console.log(current_ans)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'current_s': 's_7',
                'ans_1': val,
                'voice_on': this.playing

            });

        },
    },

};
</script>

<style>
.imgbox_2 {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 850px;
}


</style>
