<template lang="">
<div>

    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                คุณต้องการทำแบบประเมินต่อหรือไม่?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="stopTesting">
                    กลับสู่หน้าหลัก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1" @click="dialog = false">

                    ทำแบบประเมินต่อ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div :style="image" class="imgbox_3 " >
        <div class="h-screen" v-resize="onResize">
            <v-btn :class="$vuetify.breakpoint.mobile ? 's_mobile' : 's_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" @click="checkStop" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" text absolute icon fab>
                <v-icon x-large color="#A84192">close</v-icon>
            </v-btn>
            <!-- <v-btn :class="$vuetify.breakpoint.mobile ? 's_next_mobile' : 's_next_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" @click="onTouch" v-show="true" text center absolute icon fab>
                <v-icon large color="#A84192">arrow_forward_ios</v-icon>
            </v-btn> -->
            <v-container v-if="$vuetify.breakpoint.xs" >
                <v-row class="align-center flex-column my-auto mx-auto " style="overflow-y: auto; height:90vh;">
                    <v-col xl="4" md="4" sm="6" xs="12" style="text-align: -webkit-center;">
                        <div class="pt-10">
                            <v-img position="center center" width="15vh" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" @load="onImgLoad">
                            </v-img>
                            <br>
                            <span>
                                <h2 class="text-purple">ท่านกำลังเข้าสู่แบบทดสอบ<br> Thai-AZQ</h2>
                                <br>
                                
                                <h3 class="text-gray"> แบบคัดกรองหาผู้มีภาวะสมองเสื่อม
                                    <br> สำหรับญาติและผู้ดูแล <br>
                                    Thai Alzheimer's Questionnaire <br>(Thai-AZQ)
                                </h3>
                            </span>
                            <br>

                            <!-- <v-img position="center center" eager width="350px" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" @load="onImgLoad">
                            </v-img> -->
                            <v-container style="  width: 90%;">
                                <v-text-field class="pb-2" v-model="full_name" label="ชื่อ" hide-details dense outlined required></v-text-field>
                                <v-text-field class="pb-2" v-model="age"  label="*อายุ" type="tel" hide-details dense outlined required></v-text-field>
                                <v-text-field class="pb-2" v-model="phone_number" label="เบอร์โทรศัพท์" type="tel" hide-details dense outlined required></v-text-field>
                            </v-container>
                            <v-btn @click="onTouch" x-large class="text-white bg-purple">เริ่มทำแบบทดสอบ</v-btn>
                            <br>
                            <br>
                        </div>

                    </v-col>
                </v-row>
            </v-container>
            <v-container v-else>
                <v-row class="align-center flex-column my-auto mx-auto ">
                    <v-col xl="4" lg="4" md="6" sm="7" xs="12" style="text-align: -webkit-center; overflow-y: auto; height:fit-content;">
                        <div class="pt-15">
                            <v-img position="center center" width="15vh" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" @load="onImgLoad">
                            </v-img>
                            <br>
                            <span>
                                <h1 class="text-purple">ท่านกำลังเข้าสู่แบบทดสอบ<br> Thai-AZQ</h1>
                                <br>
                                <br>
                                <h2 class="text-gray"> แบบคัดกรองหาผู้มีภาวะสมองเสื่อม
                                    <br> สำหรับญาติและผู้ดูแล <br>
                                    Thai Alzheimer's Questionnaire <br>(Thai-AZQ)
                                </h2>

                            </span>
                            <br>
                            <v-container style="  width: 100%;">

                                <v-text-field class="pb-2" v-model="full_name" label="ชื่อ" hide-details dense outlined required></v-text-field>
                                <v-text-field class="pb-2" v-model="age" type="tel"  label="*อายุ" hide-details dense outlined required></v-text-field>
                                <v-text-field class="pb-2" v-model="phone_number" type="tel" label="เบอร์โทรศัพท์" hide-details dense outlined required></v-text-field>
                            </v-container>
                            <br>
                            <!-- <v-img position="center center" eager width="350px" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" @load="onImgLoad">
                            </v-img> -->
                            <v-btn @click="onTouch" x-large class="text-white bg-purple">เริ่มทำแบบทดสอบ</v-btn>
                            <br>
                            <br>
                            <br>
                        </div>

                    </v-col>
                </v-row>
            </v-container>

            <!---
            <v-img  position="center center" eager :contain="!$vuetify.breakpoint.mobile" height="100vh" src="@/assets/img/checkmong/azq/scene5.gif" @load="onImgLoad"></v-img> -->
            <!-- <v-img v-if="$vuetify.breakpoint.mobile" position="center center" height="100vh" eager @load="onImgLoad" src="@/assets/img/checkmong/azq/scene1.gif"></v-img> -->

        </div>
    </div>

</div>
</template>

<script>
import {
    LIFFID
} from '@/constants'
export default {
    data() {
        return {
            full_name: '',
            age: '',
            phone_number: '',
            bw: '',
            current_scene_w: 0,
            windowSize: {
                x: 0,
                y: 0,
            },
            dialog: false,
            isLoaded: true,
            time: '',
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_blue1.jpg')})`
            },
        };
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    mounted() {
        // this.func();
        this.onResize()
        this.bw = this.detectBrowser()
        this.getName()
        //console.log(this.bw)

    },
    methods: {
        detectBrowser() {
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                return 'Opera';
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                return 'Firefox';
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
                return 'IE'; //crap
            } else {
                return 'Unknown';
            }
        },
        getName(){
            if (this.$store.state.user_info.fullname){this.full_name = this.$store.state.user_info.fullname}
            if (this.$store.state.user_info.phone_number){this.phone_number =  this.$store.state.user_info.phone_number}
            if (this.$store.state.user_info.age){this.age =  this.$store.state.user_info.age}
            
        },
        checkStop() {
            // 
            clearTimeout(this.time);
            this.dialog = true;
            //console.log('(this.time):', this.time)

        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:', this.windowSize)
            this.current_scene_w = this.windowSize.y * 0.45
            //console.log('current_scene_w:', this.current_scene_w)
        },
        onImgLoad() {

            setTimeout(() => {
                this.isLoaded = true
                //console.log('isLoaded---->', this.isLoaded)
            }, 500);
        },
        onTouch() {
            const azq_ans_temp = this.$store.state.azq_ans
            //console.log(this.azq_ans_temp)
            // //console.log(current_ans)
            clearTimeout(this.time);
            this.isLoaded = false
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'current_s': 's_6_5',
                'voice_on': false,
                'full_name': this.full_name,
                'phonenumber': this.phone_number,
                'age': this.age,

            });

        },
        stopTesting() {
            window.liff.closeWindow();
            const azq_ans_temp = this.$store.state.azq_ans
            //console.log(this.azq_ans_temp)
            // //console.log(current_ans)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'stop_testing': '1'
            });

        },

    },

};
</script>

<style>
.imgbox_3 {
    background-size: cover !important;
    background-position: center !important;
    height: auto;
    /*aspect-ratio:1.7777777777777777777 !important;*/
}

.w-screen {
    width: 100vw;
}

.h-screen {
    height: 100vh;
    text-align: center;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}
</style>
