<template lang="">
<div>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                คุณต้องการทำแบบประเมินต่อหรือไม่?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="stopTesting">
                    กลับสู่หน้าหลัก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1"  @click="dialog = false">
                    ทำแบบประเมินต่อ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="modalAddname" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                ตรวจสอบชื่อ-นามสกุล
            </v-card-title>
            <v-card-text class="pt-1">
                <!-- <v-select class="custom-field" v-model="prefix" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                   -->
                <v-text-field v-model="full_name" label="ชื่อ-สกุล" dense outlined required></v-text-field>
                <v-text-field v-model="age" type="tel"  label="อายุ" dense outlined required></v-text-field>
                <v-text-field v-model="phone_number" type="tel"  label="เบอร์โทรศัพท์" dense outlined required></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="modalAddname = false">
                    ยกเลิก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1" @click="printThis">
                    ดาวน์โหลดผล AZQ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <button @click.preventDefault="printThis">Print!</button> -->

    <div :style="image" class="imgbox_3">
        <div class="h-screen" v-resize="onResize">
            <v-btn :class="$vuetify.breakpoint.mobile ? 's_mobile' : 's_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" @click="checkStop" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" text absolute icon fab>
                <v-icon x-large color="#A84192">close</v-icon>
            </v-btn>

            <!-- :html-to-pdf-options="htmlToPdfOptions" -->
            <vue-html2pdf :html-to-pdf-options="htmlToPdfOptions" :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="1600" :filename="filename ? full_name : filename" :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-content-width="100%" ref="html2Pdf" @progress="onProgress($event)">
                <section slot="pdf-content" ref="printcontent">
                        <v-row class="align-center flex-column my-auto mx-auto" style="margin:10px;">
                            <v-col  lg="12" md="12" sm="12" xs="12"  style="text-align: -webkit-center;">
                                <div>
                                    <h2 class="text-purple">ผลการทดสอบ</h2>
                                    <span>แบบคัดกรองหาผู้มีภาวะสมองเสื่อมสำหรับญาติและผู้ดูแล Thai Alzheimer's Questionnaire (Thai-AZQ)</span>
                                    <br>
                                    <br>
                                    <v-card outlined style=" border-radius: 7px;">
                                        <v-card-text>
                                            <v-simple-table dense class="overflow-y-auto">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                                คำถาม
                                                            </th>
                                                            <th class="text-center">
                                                                <span>ตอบ</span>
                                                            </th>
                                                            <th class="text-center">
                                                                คะแนน
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in dataset" :key="item.name" style="font-size:10px">
                                                            <td>{{ item.name }}</td>
                                                            <td class="text-right">{{ item.ans ? 'ใช่' : 'ไม่ใช่' }}</td>
                                                            <td class="text-right">{{ item.ans ? item.a_point :  0 }}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card-text>
                                    </v-card>
                                    <!-- <v-img  src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png" contain  max-height="15vh" ></v-img> -->
                                    <br>
                                    <v-row style="margin-top:-25px">

                                        <v-col cols="12">
                                            <v-card class="mx-auto" outlined>
                                                <v-list-item three-line>
                                                    <v-list-item-content style="transform: translate(7%,0%);">
                                                        <v-card-text class="overflow-y-auto" style="height:110px;padding: 0px;">
                                                            <span v-html="total_point_detail.mean"></span>
                                                            <br><br>
                                                            <span v-html="total_point_detail.suggest"></span>
                                                        </v-card-text>
                                                    </v-list-item-content>

                                                    <v-list-item-avatar tile size="80" color="white">
                                                        <h3 class="text-black text-right"> <b>
                                                                <h2>{{total_point}}</h2>
                                                            </b> คะแนน</h3>
                                                    </v-list-item-avatar>
                                                </v-list-item>

                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <!-- <v-btn @click="onTouch" x-large rounded class="text-white bg-purple">เริ่มทำแบบสอบถาม</v-btn> -->
                                    <div  >
                                        <v-row>

                                <v-col cols="2" style="transform: translate(10%,-7%);">
                                    <v-img eager contain width="70px" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png"></v-img>
                                </v-col>

                                            <!-- <v-img contain eager width="120px" height="70px" src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png"></v-img> -->
                                            <v-col cols="7" style="transform: translate(7%,0%); padding-right:0px; padding-left:0px; font-size:13px">
              
                                                                ทดสอบเมื่อ : {{new Date().toLocaleString('th-TH', { dateStyle: 'long'})}}
                                                                <br>
                                                                
                                                                ผู้ทำแบบทดสอบ : {{full_name}}{{' '}}
                                                                {{'   '}}&nbsp;&nbsp;&nbsp;
                                                                อายุ : {{age}} {{' ปี '}}
                                                                {{'   '}}&nbsp;&nbsp;&nbsp;
                                                                เบอร์โทรศัพท์ : {{phone_number}}
                              
                                            </v-col>
                                <v-col cols="3" style="max-width: 20%; transform: translate(20%,0%);">
                                    <v-img eager contain width="1000px" src="@/assets/img/checkmong/logo/hhc_eisai.jpg"></v-img>
                                    <!-- <v-img eager contain  width="700px"  src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png"></v-img> -->
                                </v-col>
                                            </v-row> </div> </div> </v-col> </v-row> 
                    </section> </vue-html2pdf> <v-container>
                                                <v-row class="align-center flex-column  ">
                                                    <v-col lg="8" md="8" sm="12" xs="12" style="text-align: -webkit-center;">
                                                        <div class="pt-0 ">
                                                            <!-- <v-img position="center center" eager width="100px" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" @load="onImgLoad">
                            </v-img> -->
                                                            <!-- <v-img  src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png" contain   ></v-img> -->

                                                            <h2 class="text-purple">ผลการทดสอบ</h2>
                                                            <span class="text-gray">แบบคัดกรองหาผู้มีภาวะสมองเสื่อม<br>สำหรับญาติและผู้ดูแล <br> Thai Alzheimer's Questionnaire (Thai-AZQ)</span>
                                                            <br>
                                                            <br>
                                                            <!-- <v-simple-table class="overflow-y-auto" style=" border-radius: 7px; background-color: rgb(255 255 255 / 50%);" :style="{ 'height': (windowHeight-padding_bottom) + 'px' }">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                คำถาม
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <span>ตอบ</span>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                คะแนน
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="text-gray">
                                                                        <tr v-for="item in dataset" :key="item.name">
                                                                            <td>{{ item.name }}</td>
                                                                            <td class="text-right">{{ item.ans ? 'ใช่' : 'ไม่ใช่' }}</td>
                                                                            <td class="text-right">{{ item.ans ? item.a_point :  0 }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table> -->

                                                            <!-- <br> -->
                                                            <v-card class="mx-auto" outlined style="background-color: rgb(255 255 255 / 50%);">
                                                                <v-list-item three-line>
                                                                    <v-list-item-content>
                                                                        <v-card-text class="overflow-y-auto pa-1 text-gray" style="height:100px;padding: 0px; ">
                                                                            <span v-html="total_point_detail.mean"></span>
                                                                            <br>
                                                                            <br>
                                                                            <span v-html="total_point_detail.suggest"></span>
                                                                        </v-card-text>
                                                                    </v-list-item-content>

                                                                    <v-list-item-avatar tile size="70" color="white" style="border-radius:5px important;">
                                                                        <h3 class="text-black text-right"> <b>
                                                                                <h2>{{total_point}}</h2>
                                                                            </b> คะแนน</h3>
                                                                    </v-list-item-avatar>
                                                                </v-list-item>
                                                                <hภ style="color:blue">** โปรดทำการ screenshort ผลคะแนน</hภ>
                                                                <br>
                                                                <v-card-actions class="justify-space-between align-center pt-0">

                                                                    <v-btn class="bg-purple text-white ml-4" @click="modalAddname = true">ดาวน์โหลดผลคะแนน</v-btn>

                                                                    <v-btn class="mr-4" @click="onTouch" icon fab>
                                                                        ต่อไป
                                                                        <v-icon large color="#A84192">arrow_forward_ios</v-icon>

                                                                    </v-btn>

                                                                </v-card-actions>

                                                            </v-card>

                                                        </div>

                                                    </v-col>
                                                </v-row>
                                                </v-container>

                                    </div>
                                </div>

        </div>
</template>

<script>
    import {
    LIFFID
} from '@/constants'
import VueHtml2pdf from 'vue-html2pdf'
import html2canvas from 'html2canvas';
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    props: ['score'],
    components: {
        VueHtml2pdf
    },
    data() {
        return {
            bw:'',
            filename: '',
            modalAddname: false,
            phone_number: '',
            prefix: '',
            full_name: '',
            age: '',
            total_point: 0,
            windowHeight: 0,
            padding_bottom: 370,
            current_scene_w: 0,
            windowSize: {
                x: 0,
                y: 0,
            },
            dialog: false,
            isLoaded: true,
            play_icon: 'record_voice_over',
            playing: false,
            hidden: true,
            audio: new Audio(require('@/assets/img/checkmong/azq/audio/voice/21.mp3')),
            yes_png: require("@/assets/img/checkmong/azq/button/Yes.png"),
            no_png: require("@/assets/img/checkmong/azq/button/No.png"),
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_blue1.jpg')})`
            },
            dataset: [],
            total_point_detail: {},
            htmlToPdfOptions: {
                margin: 0,
                filename: '',
                image: {
                        type: 'jpeg', 
                        quality: 1
                    },
                
                    enableLinks: false,
                
                    html2canvas: {
                        scale: 2,
                        useCORS: false
                    },
                
                    jsPDF: {
                        unit: 'in',
                        format: 'a4',
                        orientation: 'portrait'
                    }

            }
        };
    },
    created() {
        //console.log(this.score)
    },
    mounted() {
        this.onResize()
        this.setDataset()
        this.setTotalPointDetail()
        this.bw = this.detectBrowser()
        this.getName()
        // const azq_ans_temp = this.$store.state.azq_ans
        // this.submitVal()
    },
    watch: {
        'audio.paused'(val) {
            //console.log(val)
        }
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },

    methods: {
        async submitVal() {
            //console.log('azq_type:',this.$store.state.azq_type.azq_type)
            if (this.$store.state.azq_type.azq_type == 'azq_public'){
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(azq_ans_temp)
                await Vue.axios.post(APIURL + '/add_azq_record_anonymous', {
                    "ans1": azq_ans_temp.ans_1,
                    "ans2": azq_ans_temp.ans_2,
                    "ans3": azq_ans_temp.ans_3,
                    "ans4": azq_ans_temp.ans_4,
                    "ans5": azq_ans_temp.ans_5,
                    "ans6": azq_ans_temp.ans_6,
                    "ans7": azq_ans_temp.ans_7,
                    "ans8": azq_ans_temp.ans_8,
                    "ans9": azq_ans_temp.ans_9,
                    "ans10": azq_ans_temp.ans_10,
                    "ans11": azq_ans_temp.ans_11,
                    "ans12": azq_ans_temp.ans_12,
                    "ans13": azq_ans_temp.ans_13,
                    "ans14": azq_ans_temp.ans_14,
                    "ans15": azq_ans_temp.ans_15,
                    "ans16": azq_ans_temp.ans_16,
                    "ans17": azq_ans_temp.ans_17,
                    "ans18": azq_ans_temp.ans_18,
                    "ans19": azq_ans_temp.ans_19,
                    "ans20": azq_ans_temp.ans_20,
                    "ans21": azq_ans_temp.ans_21,
                    "score": parseInt(azq_ans_temp.point),
                    "age": this.age,
                    "full_name": this.full_name,
                    "phone_number": this.phone_number,

                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                })
            }
            else{
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(azq_ans_temp)
                await Vue.axios.post(APIURL + '/add_azq_record', {
                    "user_id": azq_ans_temp.user_id,
                    "ans1": azq_ans_temp.ans_1,
                    "ans2": azq_ans_temp.ans_2,
                    "ans3": azq_ans_temp.ans_3,
                    "ans4": azq_ans_temp.ans_4,
                    "ans5": azq_ans_temp.ans_5,
                    "ans6": azq_ans_temp.ans_6,
                    "ans7": azq_ans_temp.ans_7,
                    "ans8": azq_ans_temp.ans_8,
                    "ans9": azq_ans_temp.ans_9,
                    "ans10": azq_ans_temp.ans_10,
                    "ans11": azq_ans_temp.ans_11,
                    "ans12": azq_ans_temp.ans_12,
                    "ans13": azq_ans_temp.ans_13,
                    "ans14": azq_ans_temp.ans_14,
                    "ans15": azq_ans_temp.ans_15,
                    "ans16": azq_ans_temp.ans_16,
                    "ans17": azq_ans_temp.ans_17,
                    "ans18": azq_ans_temp.ans_18,
                    "ans19": azq_ans_temp.ans_19,
                    "ans20": azq_ans_temp.ans_20,
                    "ans21": azq_ans_temp.ans_21,
                    "score": parseInt(azq_ans_temp.point),

                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                })
            }


        },
        detectBrowser() { 
            if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
                return 'Opera';
            } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
                return 'Chrome';
            } else if(navigator.userAgent.indexOf("Safari") != -1) {
                return 'Safari';
            } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
                return 'Firefox';
            } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
                return 'IE';//crap
            } else {
                return 'Unknown';
            }
        },
        getName(){
            this.full_name = this.$store.state.azq_ans.full_name
            this.phone_number =  this.$store.state.azq_ans.phonenumber
            this.age =  this.$store.state.azq_ans.age
        },
        async downloadImg(url) {
            //console.log(url)
            // window.liff.closeWindow();
            // var new_url = URL.createObjectURL(url);
            // let blob = new Blob(url,{type:"application/pdf"})
            // let downloadURL = URL.createObjectURL(blob);
            // window.liff.openWindow({
            //     url:new_url,
            //     external: true
            // });
            // window.liff.openWi   ndow({
            //     url:"/export_azq",
            //     external: true
            // });
            const azq_ans_temp = this.$store.state.azq_ans
            var azq_val = azq_val +'&ans_1='+azq_ans_temp.ans_1;
            azq_val = azq_val +'&ans_2='+azq_ans_temp.ans_2;
            azq_val = azq_val +'&ans_3='+azq_ans_temp.ans_3;
            azq_val = azq_val +'&ans_4='+azq_ans_temp.ans_4;
            azq_val = azq_val +'&ans_5='+azq_ans_temp.ans_5;
            azq_val = azq_val +'&ans_6='+azq_ans_temp.ans_6;
            azq_val = azq_val +'&ans_7='+azq_ans_temp.ans_7;
            azq_val = azq_val +'&ans_8='+azq_ans_temp.ans_8;
            azq_val = azq_val +'&ans_9='+azq_ans_temp.ans_9;
            azq_val = azq_val +'&ans_10='+azq_ans_temp.ans_10;
            azq_val = azq_val +'&ans_11='+azq_ans_temp.ans_11;
            azq_val = azq_val +'&ans_12='+azq_ans_temp.ans_12;
            azq_val = azq_val +'&ans_13='+azq_ans_temp.ans_13;
            azq_val = azq_val +'&ans_14='+azq_ans_temp.ans_14;
            azq_val = azq_val +'&ans_15='+azq_ans_temp.ans_15;
            azq_val = azq_val +'&ans_16='+azq_ans_temp.ans_16;
            azq_val = azq_val +'&ans_17='+azq_ans_temp.ans_17;
            azq_val = azq_val +'&ans_18='+azq_ans_temp.ans_18;
            azq_val = azq_val +'&ans_19='+azq_ans_temp.ans_19;
            azq_val = azq_val +'&ans_20='+azq_ans_temp.ans_20;
            azq_val = azq_val +'&ans_21='+azq_ans_temp.ans_21;
            azq_val = azq_val +'&total_point='+azq_ans_temp.point;
            azq_val = azq_val +'&full_name='+this.full_name;
            azq_val = azq_val +'&age='+this.age;
            azq_val = azq_val +'&phone_number='+this.phone_number;


            window.open('/export_azq?'+azq_val+'&openExternalBrowser=1')
            // wnd.close();÷\

            // window.liff.openWindow({
            //     url:"/export_azq?"+azq_val,
            //     external: true
            // });
            // window.liff.closeWindow();
        },

        async printThis() {
            this.modalAddname = false
            //console.log("printing..");
            // const el = this.$refs.printcontent;

            // const options = {
            //     type: "dataURL",
            // };
            // const printCanvas = await html2canvas(el, options);

            // const link = document.createElement("a");
            // link.setAttribute("download", "ผลทดสอบ AZQ.png");
            // link.setAttribute(
            //     "href",
            //     printCanvas
            //     .toDataURL("image/png")
            //     .replace("image/png", "image/octet-stream")
            // );

            if (window.liff.isInClient()) {
                //console.log("load in liff .....");
                // // //console.log(link)

                this.downloadImg()
            } else {
                //console.log("load in browser .....");
                // //console.log(printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"))
                // link.click();
                // this.downloadImg()
                // if 
                // this.downloadImg()

                if(this.bw == 'Safari' && (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs)){
                    this.downloadImg()
                }
                else{
                    this.generateReport()
                    // this.downloadImg()
                }
         
              

                
            }

        },
        onProgress(event) {
            //console.log(`Processed: ${event} / 100`);
        },
        setTotalPointDetail() {
            if (this.total_point <= 3) {
                this.total_point_detail = {
                    'range': 'คะแนน 0-3',
                    'mean': 'ภาวะความจำปกติ <br> (Normal cognition)',
                    'suggest': 'ท่านมีความจำปกติ ควรหลีกเลี่ยงและควบคุมปัจจัยเสี่ยง',
                }
            }
            if (this.total_point >= 4 && this.total_point <= 12) {
                this.total_point_detail = {
                    'range': 'คะแนน 4-12',
                    'mean': 'สงสัยมีภาวะผิดปกติทางพุทธิปัญญาเล็กน้อย <br> (Mild cognitive impairment)',
                    'suggest': 'ท่านเริ่มมีความผิดปกติของสมอง<br>เพียงเล็กน้อย ควรค้นหาปัจจัยเสี่ยง และควบคุม ติดตามอาการ หากมีคะแนนที่เพิ่มมากขึ้น ควรพบแพทย์',
                }
            }
            if (this.total_point >= 13) {
                this.total_point_detail = {
                    'range': 'คะแนนตั้งแต่ 13',
                    'mean': "สงสัยมีภาวะสมองเสื่อมอัลไซเมอร์ <br>(Alzheimer's disease)",
                    'suggest': 'ควรพบแพทย์เพื่อตรวจหาสาเหตุ และทำการรักษา',
                }
                // } else {
                //     this.total_point_detail = {
                //         'range': 'คะแนนตั้งแต่ 13',
                //         'mean': "สงสัยมีภาวะสมองเสื่อมอัลไซเมอร์ \n(Alzheimer's disease)",
                //         'suggest': 'ควรพบแพทย์เพื่อตรวจหาสาเหตุ และทำการรักษา',
                //     }
            }

        },
        setDataset() {
            const azq_ans_temp = this.$store.state.azq_ans
            this.total_point = azq_ans_temp.point
            //console.log(this.total_point)

            this.dataset = [{
                    name: '1. คนที่คุณดูแลเป็นคนขี้ลืมหรือไม่',
                    ans: azq_ans_temp.ans_1,
                    a_point: 1
                },
                {
                    name: '2. ถ้าใช่ เขามีอาการขี้ลืมมากขึ้นภายใน 2-3 ปีที่ผ่านมาหรือไม่',
                    ans: azq_ans_temp.ans_2,
                    a_point: 1
                },
                {
                    name: '3. คนที่คุณดูแล ชอบถามซ้ำหรือพูดซ้ำประโยคเดิมหรือเรื่องเดิมๆ หลายครั้งในวันเดียวหรือไม่',
                    ans: azq_ans_temp.ans_3,
                    a_point: 2
                },
                {
                    name: '4. คุณต้องคอยเตือนย้ำเรื่องต่างๆ เช่น เรื่องนัด หรือผู้ป่วยลืมนัดบ่อยๆ',
                    ans: azq_ans_temp.ans_4,
                    a_point: 1
                },
                {
                    name: '5. เขาวางของผิดที่มากกว่า 1 ครั้งต่อเดือน',
                    ans: azq_ans_temp.ans_5,
                    a_point: 1
                },
                {
                    name: '6. เขาจะสงสัยว่า มีใครซ่อนหรือขโมยของ ถ้าหาของนั้นไม่พบ',
                    ans: azq_ans_temp.ans_6,
                    a_point: 1
                },
                {
                    name: '7. เขามักจําวัน วันที่ เดือน ปี และเวลาไม่ได้ หรือ เช็คดูวันที่ มากกว่า 1 ครั้งต่อวัน',
                    ans: azq_ans_temp.ans_7,
                    a_point: 2
                },
                {
                    name: '8. เขารู้สึกหลงเมื่อไปสถานที่ที่ไม่คุ้นเคย',
                    ans: azq_ans_temp.ans_8,
                    a_point: 1
                },
                {
                    name: '9. เมื่ออยู่นอกบ้านหรือขณะเดินทางเขาจะรู้สึกสับสนมากกว่าอยู่ที่บ้าน',
                    ans: azq_ans_temp.ans_9,
                    a_point: 1
                },
                {
                    name: '10. ถ้าไม่นับขีดจํากัดด้านร่างกาย เขามีปัญหาด้านการการรับ-จ่ายเงิน (การใช้จ่ายเงินสด, การคํานวณเงินทอน)',
                    ans: azq_ans_temp.ans_10,
                    a_point: 1
                },
                {
                    name: '11. เขามีปัญหาการจ่ายบิลค่าใช้จ่าย หรือการจัดการเรื่องการเงิน (บิลค่าไฟฟ้า, ค่าโทรศัพท์, ค่าบัตรเครดิต)',
                    ans: azq_ans_temp.ans_11,
                    a_point: 2
                },
                {
                    name: '12. เขาลืมกินยาหรือกินยาไม่สม่ำเสมอ',
                    ans: azq_ans_temp.ans_12,
                    a_point: 1
                },
                {
                    name: '13. เขาขับรถหลงทาง หรือทําให้คุณกังวลเกี่ยวกับการขับรถของเขา',
                    ans: azq_ans_temp.ans_13,
                    a_point: 1
                },
                {
                    name: '14. เขาใช้เครื่องมืออุปกรณ์ที่เคยทําทุกวันได้ยากขึ้น เช่น เตาหุงต้ม, โทรศัพท์, รีโมทคอนโทรล',
                    ans: azq_ans_temp.ans_14,
                    a_point: 1
                },
                {
                    name: '15. ถ้าไม่นับขีดจํากัดด้านร่างกายเขาทําความสะอาดบ้าน หรือซ่อมแซมของใช้ในบ้านได้ลําบากกว่าเดิม',
                    ans: azq_ans_temp.ans_15,
                    a_point: 1
                },
                {
                    name: '16. ถ้าไม่นับขีดจํากัดด้านร่างกาย เขาเลิกเล่นกีฬา หรืองานอดิเรกที่ทําเป็นประจํา',
                    ans: azq_ans_temp.ans_16,
                    a_point: 1
                },
                {
                    name: '17. เขาเริ่มหลงทางในพื้นที่ๆคุ้นเคย เช่นในละแวกบ้าน',
                    ans: azq_ans_temp.ans_17,
                    a_point: 2
                },
                {
                    name: '18. เขาไม่เข้าใจทิศหรือจําทางไม่ได้',
                    ans: azq_ans_temp.ans_18,
                    a_point: 1
                },
                {
                    name: '19. เขามีปัญหาในการนึกคําเรียกสิ่งต่างๆ นอกจากชื่อคน',
                    ans: azq_ans_temp.ans_19,
                    a_point: 1
                },
                {
                    name: '20. เขาสับสนในการเรียกชื่อสมาชิกในครอบครัว หรือในกลุ่มเพื่อนสนิท',
                    ans: azq_ans_temp.ans_20,
                    a_point: 2
                },
                {
                    name: '21. เขามีปัญหาในการจำชื่อคนที่คุ้นเคย',
                    ans: azq_ans_temp.ans_21,
                    a_point: 2
                },
            ]
        },
        generateReport() {
            this.modalAddname = false
            //console.log('-------------')
            //console.log(this.full_name)
            //console.log(this.full_name.length)
            if (!this.full_name) {
                this.filename = new Date().toLocaleString('th-TH', {
                    dateStyle: 'long'
                })+'_ผลทดสอบ AZQ'
                this.htmlToPdfOptions.filename = this.filename
                this.$refs.html2Pdf.generatePdf()
            } else {
                this.filename = this.full_name + '_' + new Date().toLocaleString('th-TH', {
                    dateStyle: 'long'
                }) + '_ผลทดสอบ AZQ'
                this.htmlToPdfOptions.filename = this.filename
                this.$refs.html2Pdf.generatePdf()
            }

            // //console.log(this.$refs.html2Pdf.downloadPdf())
        },
        onResize() {
            this.windowHeight = window.innerHeight
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:', this.windowSize)
            this.current_scene_w = this.windowSize.y * 0.45
            //console.log('current_scene_w:', this.current_scene_w)
        },
        onImgLoad() {

            this.time = setTimeout(() => {
                this.isLoaded = true
                //console.log('isLoaded---->', this.isLoaded)
            }, 10);
        },

        togleAudio(audio) {
            this.playing = !this.playing
            if (this.playing) {
                this.play(audio)
                this.audio.volume = 1
                this.play_icon = 'stop'
            } else {
                this.pause(audio)
                this.play_icon = 'record_voice_over'
            }

        },

        play(audio) {
            audio.isPlaying = true;
            audio.play();
        },

        pause(audio) {
            audio.isPlaying = false;
            audio.pause();
            audio.currentTime = 0;
        }

        ,
        stopTesting() {
                window.liff.closeWindow();
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'stop_testing': '1'
                });
            

        },
        checkStop() {
            this.dialog = true;

        },

        toggle_yes() {
            this.yes_png = require("@/assets/img/checkmong/azq/button/Yes_active.png")
            setTimeout(() => {
                this.onTouch(true)
            }, 1000);
        },
        toggle_no() {
            this.no_png = require("@/assets/img/checkmong/azq/button/No_active.png")
            setTimeout(() => {
                this.onTouch(false)
            }, 1000);
        },
        onTouch() {

            // const user_type =  this.$store.state.register_form.user_type
            const azq_ans_temp = this.$store.state.azq_ans
            // this.submitVal()
            //console.log(this.azq_ans_temp)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'current_s': 's_table',

            });
        },
    },

};
</script>

<style scoped>
.imgbox_2 {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 850px;
}

.custom-field {
    color: black;
}

.v-menu__content .v-select__selection,
.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: black !important;
}
</style>
