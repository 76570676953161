<template lang="">
<div >
        <!-- <div :style="image" class="imgbox_2"></div> -->
        <v-img :aspect-ratio="9/19" contain src="@/assets/img/checkmong/azq/scene5-6.gif"></v-img>
</div>
</template>
<script>
export default {

    data() {
            return {
                image: {backgroundImage: `url(${require('@/assets/img/checkmong/azq/scene1.gif')})`}
            };
        },
    created() {
        this.onTouch()
    },
    methods: {

        onTouch() {

                // const user_type =  this.$store.state.register_form.user_type
                // const lineid = this.$store.state.register_form.lineid
                setTimeout(() => {
                    this.$store.commit('setAzqAns', {
                    ans_pdpa: this.$store.state.azq_ans.s_pdpa,
                    current_s: 's_5_6',
                });
                }, 500);
                            this.time = setTimeout( () => {
                //console.log('auto go')
                this.onTouch()
                this.isLoaded = false
                //console.log('(this.time):',this.time)
            }, 4000);


        },
    },

};
</script>
<style>
.imgbox_2 {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 850px;
}
</style>