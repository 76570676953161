<template lang="">
<div>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                คุณต้องการทำแบบประเมินต่อหรือไม่?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="stopTesting">
                    กลับสู่หน้าหลัก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1" @click="dialog = false,onTouch()">
                    ทำแบบประเมินต่อ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div :style="image" class="imgbox_3">
        <div class="h-screen" v-resize="onResize">
            <v-btn :class="$vuetify.breakpoint.mobile ? 's_mobile' : 's_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" @click="checkStop" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" text absolute icon fab>
                <v-icon x-large color="#A84192">close</v-icon>
            </v-btn>
            <v-btn :class="$vuetify.breakpoint.mobile ? 's_next_mobile' : 's_next_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" @click="onTouchSkipp" v-show="true" text center absolute icon fab>
                <v-icon x-large color="#A84192">keyboard_double_arrow_right</v-icon>
            </v-btn>
            <v-img position="center top"  :contain="!$vuetify.breakpoint.mobile" height="100vh" src="@/assets/img/checkmong/azq/scene1.gif" @load="onImgLoad">
                <template v-slot:placeholder v-if="!isLoaded">
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
            </v-img>
            <!-- <v-img v-if="$vuetify.breakpoint.mobile" position="center center" height="100vh" eager @load="onImgLoad" src="@/assets/img/checkmong/azq/scene1.gif"></v-img> -->
        </div>
    </div>

</div>
</template>

<script>
import {
    LIFFID
} from '@/constants'
export default {
    data() {
        return {
            current_scene_w: 0,
            windowSize: {
                x: 0,
                y: 0,
            },
            dialog: false,
            isLoaded: false,
            time: '',
            bg_sound: new Audio(require('@/assets/img/checkmong/azq/audio/bg_sound.mp3')),
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_blue2.jpg')})`
            },
            // exit:false,
        };
    },

    mounted() {
        // this.func();
        this.onResize()

    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    watch: {},
    methods: {

        checkStop() {
            //
            //console.log(this.time)
            //console.log('checkStop')
            this.dialog = true
            // this.exit = true
            //console.log(clearTimeout(this.time));
        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:', this.windowSize)
            this.current_scene_w = this.windowSize.y * 0.45
            //console.log('current_scene_w:', this.current_scene_w)
        },
        onImgLoad() {
            // this.time = setTimeout(() => {
            //     this.isLoaded = true

            // }, 500);
            this.isLoaded = true
            //console.log('start-auto_go--->', this.isLoaded)
            this.time = setTimeout(() => {
                //console.log('auto go')
                this.onTouch()
                this.isLoaded = false

            }, 4000);

        },
        onTouchSkipp() {
            if (this.isLoaded == true) {
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                clearTimeout(this.time);
                this.isLoaded = false
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'current_s': 's_6'

                });
            }

        },
        onTouch() {
            if (this.isLoaded == true) {
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                clearTimeout(this.time);
                this.isLoaded = false
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'current_s': 's_1'

                });
            }

        },
        stopTesting() {
                window.liff.closeWindow();
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'stop_testing': '1'
                });
            

        },

    },

};
</script>

<style scoped>
.imgbox_3 {
    background-size: cover !important;
    background-position: center !important;
    height: auto;
    /*aspect-ratio:1.7777777777777777777 !important;*/
}

.w-screen {
    width: 100vw;
}

.h-screen {
    height: 100vh;
    text-align: center;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}
</style>
