<template lang="">
<div>
   <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                คุณต้องการทำแบบประเมินต่อหรือไม่?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple darken-1" text @click="stopTesting">
                    กลับสู่หน้าหลัก
                </v-btn>
                <v-btn color="bg-purple text-white darken-1"  @click="dialog = false">

                    ทำแบบประเมินต่อ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <div :style="image" class="imgbox_2"></div> -->
    <!-- <v-row v-if="isLoaded"  :class="$vuetify.breakpoint.mobile ? 'ans_s_mobile' : 'ans_s_desktop'">
        <v-col cols="6">
            <v-btn icon @click="toggle_yes">
                <v-img :src="yes_png" contain aspect-ratio="1.7" width="90px"></v-img>
            </v-btn>
        </v-col>
        <v-col cols="6">
            <v-btn icon  @click="toggle_no">
                <v-img :src="no_png"  contain aspect-ratio="1.7" width="90px"></v-img>
            </v-btn>
        </v-col>
    </v-row> -->



    <div :style="image" class="imgbox_3">
        <div class="h-screen" v-resize="onResize">
            <!-- <v-btn @click="togleAudio(audio)" :class="$vuetify.breakpoint.mobile ? 's_voice_mobile' : 's_voice_desktop'"  :style="{ 'margin-left': ((current_scene_w/2)-10)*-1 + 'px' }" v-if="isLoaded" :left="$vuetify.breakpoint.mobile" text absolute icon fab><v-icon large color="#A84192">{{`${play_icon}`}}</v-icon></v-btn> -->


            <v-btn :class="$vuetify.breakpoint.mobile ? 's_mobile' : 's_desktop'" :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }" @click="checkStop" v-if="isLoaded" :right="$vuetify.breakpoint.mobile" text absolute icon fab>
                <v-icon x-large color="#A84192">close</v-icon>
            </v-btn>
                <v-btn :class="$vuetify.breakpoint.mobile ? 's_next_mobile' : 's_next_desktop'"  :style="{ 'margin-left': ((current_scene_w/2)-56) + 'px' }"   v-if="isLoaded" :right="$vuetify.breakpoint.mobile" @click="onTouch" v-show="true" text center absolute icon fab>
                <v-icon large color="#A84192">arrow_forward_ios</v-icon>
            </v-btn>
            <v-btn :class="$vuetify.breakpoint.mobile ? 's_next_mobile_2' : 's_next_desktop_2'"  bottom  v-if="isLoaded" :right="$vuetify.breakpoint.mobile" @click="onTouch" v-show="true" elevation="2" center absolute icon fab>
                <span style="font-size:1.5rem; color:white">ต่อไป</span><v-icon right large color="#A84192">arrow_forward_ios</v-icon>
            </v-btn>
            <v-img  position="center top"  :contain="!$vuetify.breakpoint.mobile" height="100vh" :src="require('@/assets/img/checkmong/azq/score/set_1/'+score+'.gif')" @load="onImgLoad">
                <template v-slot:placeholder v-if="!isLoaded">
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
            </v-img>
            <!-- <v-img v-if="$vuetify.breakpoint.mobile" position="center center" height="100vh" eager @load="onImgLoad" src="@/assets/img/checkmong/azq/scene1.gif"></v-img> -->
            
        </div>
    </div>
</div>
</template>

<script>
    import {
    LIFFID
} from '@/constants'
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    props: ['score'],
    
    data() {
        return {
            full_name:'',
            phone_number:'',
            age:'',
            current_scene_w: 0,
            dialog: false,
            isLoaded: false,
            play_icon:'record_voice_over',
            playing: false,
            hidden: true,
            audio : new Audio(require('@/assets/img/checkmong/azq/audio/voice/21.mp3')), 
            yes_png: require("@/assets/img/checkmong/azq/button/Yes.png"),
            no_png: require("@/assets/img/checkmong/azq/button/No.png"),
            image: {
                backgroundImage: `url(${require('@/assets/img/checkmong/azq/bg/BG_blue1.jpg')})`
            }
        };
    },
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    created(){
        //console.log(this.score)
    },
    mounted() {

        this.pause(this.$store.state.azq_ans.bg_sound)
        this.getName()
        this.submitVal()
    },
    watch:{
        'audio.paused'(val){
            //console.log(val)
        }
    },

    methods: {
        getName(){
            this.full_name = this.$store.state.azq_ans.full_name
            this.phone_number =  this.$store.state.azq_ans.phonenumber
            this.age =  this.$store.state.azq_ans.age
        },
        onImgLoad(){

            this.time = setTimeout( () => {
            this.isLoaded = true
            //console.log('isLoaded---->',this.isLoaded)
        }, 10);
        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
            //console.log('windowSize:',this.windowSize)
            this.current_scene_w = this.windowSize.y*0.45
            //console.log('current_scene_w:',this.current_scene_w)
        },
        async submitVal() {
            console.log('azq_type:',this.$store.state.azq_type.azq_type)
            if (this.$store.state.azq_type.azq_type == 'azq_public'){
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(azq_ans_temp)
                await Vue.axios.post(APIURL + '/add_azq_record_anonymous', {
                    "ans1": azq_ans_temp.ans_1,
                    "ans2": azq_ans_temp.ans_2,
                    "ans3": azq_ans_temp.ans_3,
                    "ans4": azq_ans_temp.ans_4,
                    "ans5": azq_ans_temp.ans_5,
                    "ans6": azq_ans_temp.ans_6,
                    "ans7": azq_ans_temp.ans_7,
                    "ans8": azq_ans_temp.ans_8,
                    "ans9": azq_ans_temp.ans_9,
                    "ans10": azq_ans_temp.ans_10,
                    "ans11": azq_ans_temp.ans_11,
                    "ans12": azq_ans_temp.ans_12,
                    "ans13": azq_ans_temp.ans_13,
                    "ans14": azq_ans_temp.ans_14,
                    "ans15": azq_ans_temp.ans_15,
                    "ans16": azq_ans_temp.ans_16,
                    "ans17": azq_ans_temp.ans_17,
                    "ans18": azq_ans_temp.ans_18,
                    "ans19": azq_ans_temp.ans_19,
                    "ans20": azq_ans_temp.ans_20,
                    "ans21": azq_ans_temp.ans_21,
                    "score": parseInt(azq_ans_temp.point),
                    "age": this.age,
                    "full_name": this.full_name,
                    "phone_number": this.phone_number,

                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                })
            }
            else{
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(azq_ans_temp)
                await Vue.axios.post(APIURL + '/add_azq_record', {
                    "user_id": azq_ans_temp.user_id,
                    "ans1": azq_ans_temp.ans_1,
                    "ans2": azq_ans_temp.ans_2,
                    "ans3": azq_ans_temp.ans_3,
                    "ans4": azq_ans_temp.ans_4,
                    "ans5": azq_ans_temp.ans_5,
                    "ans6": azq_ans_temp.ans_6,
                    "ans7": azq_ans_temp.ans_7,
                    "ans8": azq_ans_temp.ans_8,
                    "ans9": azq_ans_temp.ans_9,
                    "ans10": azq_ans_temp.ans_10,
                    "ans11": azq_ans_temp.ans_11,
                    "ans12": azq_ans_temp.ans_12,
                    "ans13": azq_ans_temp.ans_13,
                    "ans14": azq_ans_temp.ans_14,
                    "ans15": azq_ans_temp.ans_15,
                    "ans16": azq_ans_temp.ans_16,
                    "ans17": azq_ans_temp.ans_17,
                    "ans18": azq_ans_temp.ans_18,
                    "ans19": azq_ans_temp.ans_19,
                    "ans20": azq_ans_temp.ans_20,
                    "ans21": azq_ans_temp.ans_21,
                    "score": parseInt(azq_ans_temp.point),

                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                })
            }


        },
    //  playSound (val) {
    //     var audio = new Audio(require('@/assets/img/checkmong/azq/audio/bg_sound.mp3'));
    //     audio.loop = true;
    //     audio.play();
    //     //console.log(val)
    //     if(val){
    //         this.playing = true

    //     }
    //     else{
    //         audio.pause();
    //         this.playing = false

    //     }

    // },
    stopTesting() {
                window.liff.closeWindow();
                const azq_ans_temp = this.$store.state.azq_ans
                //console.log(this.azq_ans_temp)
                // //console.log(current_ans)
                this.$store.commit('setAzqAns', {
                    ...azq_ans_temp,
                    'stop_testing': '1'
                });
            

        },
                checkStop(){
            this.dialog = true;

        },
    togleAudio(audio){
        this.playing = !this.playing
        if(this.playing){
            this.play(audio)
            this.audio.volume = 1
            this.play_icon = 'stop'
        }
        else{
            this.pause(audio)
            this.play_icon = 'record_voice_over'
        }
        
    },

    play (audio) {
      audio.isPlaying = true;
      audio.play();
    },
    
    pause (audio) {
      audio.isPlaying = false;
      audio.pause();
      audio.currentTime = 0;
    }
    ,
        toggle_yes(){
            this.yes_png = require("@/assets/img/checkmong/azq/button/Yes_active.png")
            setTimeout(() => {
                this.onTouch(true)
            }, 1000);
        },
        toggle_no(){
            this.no_png = require("@/assets/img/checkmong/azq/button/No_active.png")
            setTimeout(() => {
                this.onTouch(false)
            }, 1000);
        },
        onTouch() {

            // const user_type =  this.$store.state.register_form.user_type
            const azq_ans_temp = this.$store.state.azq_ans
            
            //console.log(this.azq_ans_temp)
            // //console.log(current_ans)
            this.$store.commit('setAzqAns', {
                ...azq_ans_temp,
                'current_s':'s_score_1',

            });
        },
    },

};
</script>

<style scoped>
.imgbox_2 {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 850px;
    width: fit-content;
    height: fit-content;
    border-radius: 14px;
    /* margin: 4px; */
    padding: 9px;
    color: white;
    background-color: #A84192;
}
.s_next_mobile_2 {
    right: 38% !important;
    bottom: 6% !important;
    width: fit-content;
    height: fit-content;
    border-radius: 14px;
    /* margin: 4px; */
    padding: 3px 7px 3px 7px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7) ;
}

.s_next_desktop_2 {
    margin-left: -3rem !important;
    
    bottom: 11% !important;
        width: fit-content;
    height: fit-content;
    border-radius: 14px;
    /* margin: 4px; */
    padding: 3px 7px 3px 7px;
    color: white;
    background-color: currentColor;
}
</style>

